'use client';

import { CookieIcon } from 'lucide-react';

const CookieConfigurationComponent = () => {
  return (
    <div
      className="bottom-6 right-6 z-[100000] fixed  bg-gray-500 bg-opacity-20 p-2 rounded-full shadow-lg cursor-pointer"
      id="open_preferences_center"
    >
      <CookieIcon className="text-gray-700" />
    </div>
  );
};

export default CookieConfigurationComponent;
