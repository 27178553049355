'use client';
import useMixPanel from '@/hooks/useMixPanel';
import { Menu, X } from 'lucide-react';
import { useState } from 'react';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import { Route } from './Navigation.types';

const NavigationMobile = ({ routes }: { routes: Route[] }) => {
  const [open, setOpen] = useState(false);
  const Mixpanel = useMixPanel();

  return (
    <>
      <button
        onClick={() => {
          Mixpanel.track('press burger menu', {
            screen: 'nav bar mobile'
          });
          setOpen(true);
        }}
        className="lg:hidden"
      >
        {!open ? <Menu /> : <X />}
      </button>
      <DrawerMenu open={open} setOpen={setOpen} routes={routes} />
    </>
  );
};

export default NavigationMobile;
