import { Route } from "@/components/sections/navbar/NavigationMenu/Navigation.types";
import { usePathname } from "next/navigation";

// hook to get active route
const useActiveLink = () => {
  const pathname = usePathname();
  const clearPathname = (path?: string) => {
    if (!path) return;
    return path.substring(path.lastIndexOf("/") + 1);
  };
  const formatedPathname = clearPathname(pathname);

  const childrenRouteActive = (subroutes: Route[]) =>
    subroutes.some(
      (subroute) => clearPathname(subroute?.href) === formatedPathname
    );
  const routeIsActive = (route: Route) => {
    if (route?.subroutes) {
      return childrenRouteActive(route?.subroutes);
    }
    return clearPathname(route?.href) === formatedPathname;
  };
  return { routeIsActive };
};

export default useActiveLink;
