import api from '@/services/api/api';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetOrganization = (organizationName?: string) => {
  return useQuery({
    queryKey: ['Getorganization', organizationName],
    queryFn:
      organizationName === undefined
        ? skipToken
        : () => api.organization.getOrganization(organizationName)
  });
};

export default useGetOrganization;
