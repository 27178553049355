'use client';

import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization/useGetOrganization';
import useMixPanel from '@/hooks/useMixPanel';
import { useRootStore } from '@/providers/root-store-provider';
import { useUser } from '@auth0/nextjs-auth0/client';
import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import LanguageChanger from '../LanguageChangerButton/LanguageChanger';
import NavBarNavigationMenu from '../NavigationMenu/NavBarNavigationMenu';

const NavBar = () => {
  const { t } = useTranslation('navigation');
  const { user: auth, isLoading } = useUser();
  const { organizationName } = useParams<{ organizationName: string }>();
  const { data: organization } = useGetOrganization(organizationName);
  const Mixpanel = useMixPanel();

  const hasHydrated = useRootStore((state) => state.hasHydrated);
  const inIframe = useRootStore((store) => store.inIframe);

  const isAppLoading = !hasHydrated || isLoading;

  const renderLoginButton = () => {
    const href =
      inIframe && process.env.NEXT_PUBLIC_ZAZU_URL
        ? process.env.NEXT_PUBLIC_ZAZU_URL
        : '/api/auth/login';
    const target = inIframe ? '_blank' : '_self';
    const rel = inIframe ? 'noopener noreferrer' : undefined;

    return (
      <Button
        variant="secondary"
        onClick={() => Mixpanel.track('press login button')}
        asChild
      >
        <Link target={target} rel={rel} href={href}>
          {t('Session.Login')}
        </Link>
      </Button>
    );
  };

  const renderNavBarContent = () => {
    if (isAppLoading) {
      return <Skeleton className="h-10 w-[120px]" />;
    }
    return auth ? <NavBarNavigationMenu /> : renderLoginButton();
  };

  return (
    <div className="w-full py-5">
      <div className="flex flex-row items-center justify-between px-[1.25rem] lg:container">
        {organization ? (
          <div className="flex flex-col items-start ">
            <h1 className="text-xl text-primary font-extrabold">
              {organization.name}
            </h1>
            <p className="text-xs font-thin">
              Powered by{' '}
              <span className="text-primary font-semibold">Zazu</span>
            </p>
          </div>
        ) : (
          <Link
            onClick={() => Mixpanel.track('logo go home button')}
            className="flex flex-row items-center gap-2"
            href="/"
          >
            <Image
              priority
              src="/images/Ocean-flow-Logo.png"
              width={45}
              height={45}
              alt="Zazu"
            />
            <h3 className="m-0 font-bold">Zazu</h3>
          </Link>
        )}
        <div className="flex items-center gap-4">
          <LanguageChanger />
          {renderNavBarContent()}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
