import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/home/CookieConfigurationComponent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/home/cookieConsent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/navbar/NavBar/NavBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/providers/query-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/providers/rollbar-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/providers/root-store-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/providers/translation-provider.tsx")