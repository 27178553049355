import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import useActiveLink from '@/hooks/useActiveLink';
import useMixPanel from '@/hooks/useMixPanel';
import { useRootStore } from '@/providers/root-store-provider';
import { useUser } from '@auth0/nextjs-auth0/client';
import Link from 'next/link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from '../NavigationMenu/Navigation.types';

export function UserNav({ routes }: { routes: Route[] }) {
  const [open, onOpenChange] = useState(false);
  const { t } = useTranslation('navigation');
  const { routeIsActive } = useActiveLink();
  const Mixpanel = useMixPanel();
  const cleanStepServicesData = useRootStore(
    (store) => store.cleanStepServicesData
  );
  const setStep = useRootStore((store) => store.setStep);

  const handleCleanData = () => {
    cleanStepServicesData();
    setStep(1);
  };

  const { user } = useUser();
  if (user) {
    return (
      <DropdownMenu open={open} onOpenChange={onOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="relative w-8 h-8 rounded-full">
            <Avatar className="w-8 h-8">
              <AvatarImage src={user?.picture ?? ''} alt={user?.name ?? ''} />
              <AvatarFallback>{user?.name?.[0]}</AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-66 space-x-2 space-y-2 py-2"
          align="end"
          forceMount
        >
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">
                {`${user?.name}`}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {user?.email}
              </p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {routes.map((route, i) => (
            <DropdownMenuItem
              key={`userNav-${route}-${i}`}
              className={`${routeIsActive(route) ? 'bg-primary/30' : ' '}`}
              onClick={() =>
                Mixpanel.track('press ' + route.title, {
                  screen: 'nav bar desktop'
                })
              }
            >
              <Link
                onClick={() => {
                  Mixpanel.track('press ' + route.title, {
                    screen: 'nav bar desktop'
                  });
                  onOpenChange(false);
                }}
                className="w-full h-full"
                href={route?.href ? route.href : ''}
              >
                {route.title}
              </Link>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem
            onClick={() => {
              Mixpanel.track('press logOut', {
                screen: 'nav bar desktop'
              });
            }}
          >
            <a
              href="/api/auth/logout"
              className="w-full h-full"
              onClick={handleCleanData}
            >
              {t('Session.Logout')}
            </a>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
}
