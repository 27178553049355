'use client';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import useMixPanel from '@/hooks/useMixPanel';
import { usePathname, useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import i18nConfig from '../../../../../i18nConfig';

export default function LanguageChanger() {
  const { t, i18n } = useTranslation('navigation');
  const Mixpanel = useMixPanel();
  const currentLocale = i18n.language;
  const router = useRouter();
  const currentPathname = usePathname();

  const handleChange = (newLocale: string) => {
    // set cookie for next-i18n-router
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = date.toUTCString();
    document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`;

    // redirect to the new locale path
    if (currentLocale === i18nConfig.defaultLocale) {
      router.push('/' + newLocale + currentPathname);
    } else {
      router.push(
        currentPathname.replace(`/${currentLocale}`, `/${newLocale}`)
      );
    }
    router.refresh();
  };

  return (
    <Select
      onOpenChange={() =>
        Mixpanel.track('Select language dropdown', {
          value: currentLocale
        })
      }
      onValueChange={handleChange}
      value={currentLocale}
    >
      <SelectTrigger className="w-12 justify-center sm:w-28 sm:justify-between">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="es">
            <span className="hidden sm:block">{t('language.spanish')}</span>
            <span className="sm:hidden">{t('language.es')}</span>
          </SelectItem>
          <SelectItem value="en">
            <span className="hidden sm:block">{t('language.english')}</span>
            <span className="sm:hidden">{t('language.en')}</span>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
