'use client';
import Script from 'next/script';
import { useEffect } from 'react';

declare global {
  interface Window {
    cookieconsent: any;
    cookieconsentInitialized: boolean;
  }
}

const CookieConsentComponent = () => {
  useEffect(() => {
    const handleOpenPreferences = (event: any) => {
      event.preventDefault();
      if (window.cookieconsent?.preferences) {
        window.cookieconsent.preferences.show();
        const modal = document.querySelector('.cc-modal');
        if (modal) {
          modal.classList.add('cookie-consent-modal');
        }
      }
    };

    const addClickListener = () => {
      const openPreferencesLink = document.getElementById(
        'open_preferences_center'
      );
      if (openPreferencesLink) {
        openPreferencesLink.addEventListener('click', handleOpenPreferences);
      }
    };

    const initializeCookieConsent = () => {
      if (!window.cookieconsentInitialized) {
        window.cookieconsentInitialized = true;
        window.cookieconsent.run({
          notice_banner_type: 'simple',
          consent_type: 'express',
          palette: 'light',
          language: 'es',
          page_load_consent_levels: ['strictly-necessary'],
          notice_banner_reject_button_hide: false,
          preferences_center_close_button_hide: false,
          page_refresh_confirmation_buttons: false,
          website_name: 'Zazu'
        });
        addClickListener();
      }
    };

    const checkCookieConsent = () => {
      if (window.cookieconsent) {
        initializeCookieConsent();
      } else {
        setTimeout(checkCookieConsent, 100);
      }
    };
    checkCookieConsent();
  }, []);

  return (
    <Script
      src="//www.freeprivacypolicy.com/public/cookie-consent/4.1.0/cookie-consent.js"
      strategy="afterInteractive"
    />
  );
};

export default CookieConsentComponent;
