import Rollbar from 'rollbar';

const baseConfig = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NEXT_PUBLIC_ENVIROMENT_NAME ?? 'development',
  payload: {
    environment: process.env.NEXT_PUBLIC_ENVIROMENT_NAME ?? 'development',
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  }
};

export const clientConfig = {
  accessToken: process.env.NEXT_PUBLIC_POST_CLIENT_ITEM_TOKEN,
  ...baseConfig
};

export const serverInstance = new Rollbar({
  accessToken: process.env.POST_SERVER_ITEM_TOKEN,
  ...baseConfig
});
